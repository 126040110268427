<template>
  <div class="full-width-table">
    <sdCards title="รายงานของห้องเรียนตามปีการศึกษา">
      <template #button>
        <a-space>
          <sdButton class="btn-add_new" transparented size="default" type="secondary" @click="goToGradeConfirmation">
            <sdFeatherIcons type="user-check" size="14" /><span>สรุปผลพัฒนาการ</span>
          </sdButton>
          <sdButton class="btn-add_new" transparented size="default" type="secondary" @click="goToOpinion">
            <sdFeatherIcons type="message-circle" size="14" /><span>ให้คำแนะนำ</span>
          </sdButton>
          <sdButton class="btn-add_new" transparented size="default" type="secondary" @click="openPrintReport">
            <sdFeatherIcons type="printer" size="14" /><span>พิมพ์เล่มรายงาน</span>
          </sdButton>
        </a-space>
      </template>

      <sdCards>
        <template #button>
          <div class="card-nav">
            <ul>
              <li :class="traffic === '1' ? 'active' : 'deactivate'">
                <router-link @click="e => handleActiveChangeTraffic(e, '1')" to="#"> เทอม 1 </router-link>
              </li>
              <li :class="traffic === '2' ? 'active' : 'deactivate'">
                <router-link @click="e => handleActiveChangeTraffic(e, '2')" to="#"> เทอม 2 </router-link>
              </li>
            </ul>
          </div>
        </template>
        <a-spin :spinning="isLoading">
          <TrafficTableWrapper>
            <div class="table-bordered table-responsive">
              <a-table :columns="locationColumns" :dataSource="locationData" :pagination="false" />
            </div>
          </TrafficTableWrapper>
        </a-spin>
      </sdCards>
    </sdCards>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { TrafficTableWrapper } from '../../style';
import { useRouter } from 'vue-router';
import { calculateAvgScoreForTopic } from '@/utility/widgetCalculator.js';

const locationColumns = [
  {
    title: 'พัฒนาการ',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'คะแนนเฉลี่ย',
    dataIndex: 'avg',
    key: 'avg',
  },
  {
    title: 'ควาบคืบหน้าการประเมิน (%)',
    dataIndex: 'percentage',
    key: 'percentage',
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
  },
];

const TrafficChannel = {
  name: 'TrafficChannel',
  components: {
    TrafficTableWrapper,
  },
  props: {
    schoolYearId: Number,
    classroomId: Number,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { getters, dispatch, state } = useStore();

    const traffic = ref('1');
    const trafficState = computed(() => store.state.chartContent.trafficChanelData);
    const print = computed(() => state.print.developmentReport);
    const classroom = computed(() => getters.getClassroomById(props.classroomId));
    const loading = ref(false);
    const isLoading = computed(() => loading.value);

    let storeTopicInfos = ref(null);

    const schoolYear = computed(() => {
      if (!classroom.value) return null;
      return state.schoolYear.data.find(schoolYear => schoolYear.id == classroom.value.schoolYearId);
    });

    const gradeLevel = computed(() => {
      if (!classroom.value) return null;
      return classroom.value.gradeLevel;
    });

    const locationData = computed(() => {
      if (!storeTopicInfos.value) {
        return [];
      }
      const results = storeTopicInfos.value.map(item => {
        let percent = 0;
        let avg = 0;

        if (item.progress.length == 2) {
          percent = traffic.value == 1 ? item.progress[0].percentage : item.progress[1].percentage;
        } else if (item.progress.length == 1) {
          percent = item.progress[0].percentage;
        }

        avg = calculateAvgScoreForTopic(traffic.value, item.reports);

        return {
          key: item.id,
          title: item.title,
          id: item.id,
          avg: avg,
          percentage: (
            <a-progress
              percent={parseInt(percent)}
              strokeWidth={5}
              status="active"
              showInfo={false}
              class="progress-et progress-secondary"
            />
          ),
          value: percent,
        };
      });
      return results;
    });

    const handleActiveChangeTraffic = (event, value) => {
      event.preventDefault();
      traffic.value = value;
      return store.dispatch('trafficChanelFilterData', value);
    };

    onMounted(() => {
      store.dispatch('trafficChanelGetData');
      getDashboardInfo();
    });

    const getDashboardInfo = async () => {
      loading.value = true;
      const form = await dispatch('getEvaluateForm', {
        standardYear: schoolYear.value.standardYear,
        gradeLevel: gradeLevel.value,
      });

      const evaluateProgress = await dispatch('getClassroomEvaluationProgress', {
        classroomId: props.classroomId,
        schoolYearId: props.schoolYearId,
      });

      const developmentReport = await dispatch('getDevelopmentReport', {
        classroomId: props.classroomId,
      });

      storeTopicInfos.value = form.map(topic => {
        const result = evaluateProgress.find(item => {
          return item.id == topic.id;
        });

        const reports = developmentReport.topics.filter(item => {
          return item.topicId == topic.id;
        });

        return {
          key: topic.id,
          title: topic.title,
          reports: reports,
          progress: result.progress,
          id: topic.id,
          avg: 0.0,
          percentage: (
            <a-progress
              percent={parseInt(0.0)}
              strokeWidth={5}
              status="active"
              showInfo={false}
              class="progress-et progress-secondary"
            />
          ),
        };
      });
      loading.value = false;
    };

    const goToOpinion = () => {
      router.push({
        name: 'classroom-opinion',
        props: {
          schoolYearId: props.schoolYearId,
          classroomId: props.classroomId,
        },
      });
    };

    const goToGradeConfirmation = () => {
      router.push({
        name: 'classroom-grade-confirmation',
        props: {
          schoolYearId: props.schoolYearId,
          classroomId: props.classroomId,
        },
      });
    };

    const openPrintReport = () => {
      // const routeData = router.resolve({
      //   name: 'print-classroom-development',
      //   props: { classroomId: props.classroomId },
      // });
      // window.open(routeData.href, '_blank');
      router.push({
        name: 'print-classroom-development',
        props: {
          classroomId: props.classroomId,
        },
      });
    };

    return {
      trafficState,
      traffic,
      handleActiveChangeTraffic,
      locationColumns,
      locationData,
      getDashboardInfo,
      schoolYear,
      gradeLevel,
      print,
      isLoading,
      goToOpinion,
      goToGradeConfirmation,
      openPrintReport,
    };
  },
};

export default TrafficChannel;
</script>
