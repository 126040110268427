const calculateAvgScoreForTopic = (termOrder = 0, reports = []) => {
  const termIds = reports.map(report => parseInt(report.termId));
  const uniqeTermIds = new Set(termIds);
  const sortedTermIds = Array.from(uniqeTermIds).sort();

  const records = reports.filter(report => {
    return report.termId == sortedTermIds[parseInt(termOrder) - 1];
  });

  if (records.length == 0) {
    return 0;
  }

  const sumScore = records.reduce((acc, record) => {
    acc = acc + record.averageScore;
    return acc;
  }, 0);

  return (sumScore / records.length).toFixed(2);
};

export { calculateAvgScoreForTopic };
